.grid_buy{

    display: grid;
    padding: 3px;
    

    @media screen and (min-width: 320px){
       grid-template-columns: repeat(2, 1fr);

    };

    @media screen and (min-width: 480px){
        grid-template-columns: repeat(2, 1fr);
 
    };

    @media screen and (min-width: 552px){
        grid-template-columns: repeat(3, 1fr);
 
    };

    @media screen and (min-width: 624px){
        grid-template-columns: repeat(5, 1fr);
 
    }; 

    @media  screen and(min-width: 750px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 960px){
       grid-template-columns: repeat(5, 1fr);
       
    };

    @media  screen and(min-width: 1024px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 1328px){
        grid-template-columns: repeat(5, 1fr);
    };

    @media  screen and (min-width: 1365px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };

    @media  screen and(min-width: 1440px){
        grid-template-columns: repeat(5, 1fr);
    };

    .col{
        margin-bottom: 3px;
        padding: 3px;
        }

    
}


.grid_specials{

    display: grid;
    padding: 3px;
    

    @media screen and (min-width: 320px){
       grid-template-columns: repeat(2, 1fr);

    };

    @media screen and (min-width: 480px){
        grid-template-columns: repeat(2, 1fr);
 
    };

    @media screen and (min-width: 552px){
        grid-template-columns: repeat(3, 1fr);
 
    };

    @media screen and (min-width: 624px){
        grid-template-columns: repeat(5, 1fr);
 
    }; 

    @media  screen and(min-width: 750px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 960px){
       grid-template-columns: repeat(5, 1fr);
       
    };

    @media  screen and(min-width: 1024px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 1328px){
        grid-template-columns: repeat(5, 1fr);
    };

    @media  screen and (min-width: 1365px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };

    @media  screen and(min-width: 1440px){
        grid-template-columns: repeat(5, 1fr);
    };

    .col{
        margin-bottom: 3px;
        padding: 3px;
        }

    
}


.grid_products{

    display: grid;
    padding: 3px;
    

    @media screen and (min-width: 320px){
       grid-template-columns: repeat(2, 1fr);

    };

    @media screen and (min-width: 480px){
        grid-template-columns: repeat(2, 1fr);
 
    };

    @media screen and (min-width: 552px){
        grid-template-columns: repeat(3, 1fr);
 
    };

    @media screen and (min-width: 624px){
        grid-template-columns: repeat(5, 1fr);
 
    }; 

    @media  screen and(min-width: 750px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 960px){
       grid-template-columns: repeat(5, 1fr);
       
    };

    @media  screen and(min-width: 1024px){
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and(min-width: 1328px){
        grid-template-columns: repeat(5, 1fr);
    };

    @media  screen and (min-width: 1365px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };

    @media  screen and (min-width: 1440px){
        grid-template-columns: repeat(5, 1fr);
    };

    .col{
        margin-bottom: 3px;
        padding: 3px;
        }

    
}

