.grid{

    display: grid;
    padding: 3px;
    

    @media screen and (min-width: 320px){
       /* background-color: blue; */ 
       grid-template-columns: repeat(2, 1fr);

    };

    @media screen and (min-width: 480px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(2, 1fr);
 
    };

    @media screen and (min-width: 552px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(3, 1fr);
 
    };

    @media screen and (min-width: 624px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(5, 1fr);
 
    }; 

    @media  screen and (min-width: 750px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and (min-width: 960px){
       /* background-color: green; */
       grid-template-columns: repeat(5, 1fr);
       
    };

    @media  screen and (min-width: 1024px){
        /* background-color: blue; */ 
        grid-template-columns: repeat(5, 1fr);
 
     };

    @media  screen and (min-width: 1328px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };

    @media  screen and (min-width: 1365px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };

    @media  screen and (min-width: 1440px){
        grid-template-columns: repeat(5, 1fr);
       /*  background-color: red;  */
    };


    .col{
    margin-bottom: 3px;
    padding: 3px;
    }
}


#card-text{
    display: none;
    

    @media (min-width: 576px){
        display: none;
    }

    @media (min-width: 768px){
        display: none;
    }



}

.card-text{ 
    text-align: justify;
}


#title-center{
    text-align: left;

    
}

#descargar{
    font-size: 18px;
}





